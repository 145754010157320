import React from 'react';
import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import FlexCard from '../../components/motorrad/flexcard';
import GiftCardBackground from '../../components/giftcardbackground';
import settings from '../../../settings';
import { Link } from "gatsby";


// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/motorrad/creditcard/moto-banner.jpg`;
const creditCard = `${settings.IMAGES_BASE_URL}/images/motorrad/home/moto-cc-home.jpg`
const CreditCard = () => {
  return (
    <Layout>
      <SEO
        title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
        description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
      />
      <div className="giftcardpage giftcardpagemini">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW MOTORRAD CREDIT CARD"
        />
        <section className="drivingschool performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2 >BMW MOTORRAD CREDIT CARD</h2>
            </div>
          </div>
          <FlexCard
              rowType='row'
              image={creditCard}
              imageAlt="BMW Mottorrad Reward"
              header="Rewards At Full Throttle"
              subheader="SAVE ON CLASSES WITH THE BMW MOTORRAD CARD"
          >
            <p>
              Accelerate your rewards with the BMW Motorrad Card – exclusively for BMW Motorrad riders. Redeem for unlimited cash back toward your BMW Motorrad Financial Services monthly payment or travel. Plus, you’ll save when you book a class with us over the phone.
              <br/><br/>
              Excludes MSF Course and Private Instruction.
            </p>
            <Link to="https://www.mybmwmotorradcard.com/41627" target="_blank" >
              <button className="btn btn-bordered">LEARN MORE ABOUT THE BMW MOTORRAD CARD</button>
            </Link>
          </FlexCard>
        </section>
      </div>
    </Layout>
  );
};

export default CreditCard;
